@if (loading) {
  <div class="skeleton-title">
    <p-skeleton height="40px" class="skeleton-title" styleClass="mb-2" />
  </div>
} @else {
  <div class="title-container">
    @if (locked) {
      <h1
        pTooltip="This doc is locked to maintain its integrity: edits are disabled. Only document editor(s) can unlock it."
      >
        <i class="pi pi-lock"></i>
        {{ title }}
      </h1>
    } @else {
      <h1>{{ title }}</h1>
    }
    <div class="title-rating">
      @if (rating > 0) {
        <i class="pi pi-star-fill yellow"></i>
        <span>{{ rating }}/5</span>
      }
    </div>
  </div>
}
