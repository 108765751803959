import {
  GoogleRessourceRole,
  GoogleMimeTypes,
  DocumentType,
  Domain,
  FileLabel,
  Like,
  People,
  Rating,
  Workflow,
  Version,
  TagDatamart,
} from 'app/types';

export interface FileResponse {
  /** The file. */
  file: File;

  /** The user permissions associated to this file */
  user_permissions?: GoogleRessourceRole;
}

export interface FilesResponse {
  /** The files. */
  files: File[];
}

export interface File {
  /** The ID of the file. */
  id: string;

  /** Shared drive of the file (for tree component) */
  drive?: any;

  /** Parent folder (for tree component) */
  parent_folder?: Folder;

  /** The domain associated to the file */
  domain?: Domain;

  /** The overlayers information of the file */
  datamart?: FileDatamart;

  /** Ratings associated to the file */
  ratings?: Rating[];

  /** Likes associated to the file */
  likes?: Like[];

  /** The labels associated to the file */
  labels?: FileLabel[];

  /** The tags associated to the file */
  tags?: TagDatamart[];

  /** The workflows of the file */
  workflows?: Workflow[];


  version?: Version;

  /** If the file is locked in google Drive */
  is_locked?: boolean;

  /** File type in Google drive */
  mime_type?: GoogleMimeTypes;

  /** The last person who modified the file */
  last_modified_by?: People;


  icon_link?: string;

  /** The date of the last modification of the file */
  last_modified?: Date;

  /** Google drive link of the file */
  webview_link?: string;

  /** The user permissions associated to this file */
  user_permissions?: GoogleRessourceRole;
}

export interface Folder {
  /** The ID of the folder. */
  id: string;

  /** The domain associated to the folder */
  domain: Domain;

  /** Date of creation of the folder */
  created_at: Date;

  /** Date of last modification of the folder */
  updated_at: Date;
}

export interface FileDatamart {
  /** The ID of the datamart. */
  id: number;

  /** The name of the file */
  name: string;

  /** The confidentiality of the file */
  confidentiality: string;

  /** The type of the document */
  document_type: DocumentType;

  /** The language of the file */
  language: string;

  /** Archived file */
  archived: boolean;

  /** The description of the file */
  description: string;

  /** The file extension */
  file_extension: string;

  /** The creator of the file */
  created_by: People;

  /** The contributors of the file */
  contributors: People[];
}

export interface ExportedPDFResponse {
  /** The PDF file url */
  url: string;
}

export enum FileErrorReason {
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  ALREADY_FLAGGED = 'ALREADY_FLAGGED',
  VERIFICATION_ALREADY_REQUESTED = 'VERIFICATION_ALREADY_REQUESTED',
  NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS',
  NOT_FOUND = 'NOT_FOUND',
  UNKNOWN = 'UNKNOWN',
  NOT_WATCHED_BY_OVERLAYER = 'NOT_WATCHED_BY_OVERLAYER',
  NO_ONGOING_VERIFICATION = 'NO_VERIFICATION_ONGOING',
  IS_SECRET = 'IS_SECRET',
  NO_DEDICATED_USER_FOR_DOMAIN = 'NO_DEDICATED_USER_FOR_DOMAIN',
  MY_DRIVE = 'MY_DRIVE',
  NO_OVERLAYER_LABELS = 'NO_OVERLAYER_LABELS',
  NO_HISTORY = 'NO_HISTORY',
  NOT_VERIFIED = 'NOT_VERIFIED',
}
