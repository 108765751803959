<div class="sensitivity-container">
  <p-dropdown
    [options]="confidentialityOptions"
    [(ngModel)]="selectedConfidentiality"
    placeholder="Confidentiality"
    class="dropdown-width"
  />
  <p-button
    label="Change file sensitivity"
    [disabled]="!selectedConfidentiality"
    (click)="applySensitivity(selectedConfidentiality)"
    class="deep-button"
  />
</div>
