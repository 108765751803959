@if (hasAScreenStatusToDisplay()) {
  <side-panel-status-screens
    [sidePanelStatus]="sidePanel.status"
    (onWatch)="watchDriveFromFileId()"
    (onRefresh)="reloadSidePanel()"
    (onSensitivity)="toggleDialog(DialogState.SECRET)"
  />
} @else {
  <div class="side-panel {{ lockedFileClass }}">
    <!-- HEADER -->
    <side-panel-title
      [title]="sidePanel.file?.datamart?.name"
      [ratings]="sidePanel.file?.ratings"
      [locked]="sidePanel.file?.is_locked"
      [loading]="sidePanel.loadingSections.includes(Sections.TITLE)"
    />

    <!-- LABELS -->
    <side-panel-labels
      [labels]="sidePanel.file?.labels"
      [loading]="sidePanel.loadingSections.includes(Sections.LABELS)"
      [locked]="sidePanel.file?.is_locked"
      (unlockFileEvent)="unlockFile()"
    />

    <!-- SPACING -->
    <div class="spacing-separator"></div>

    <!-- CREATORS / CONTRIBUTORS -->
    <side-panel-contributors
      [creator]="sidePanel.file?.datamart?.created_by"
      [contributors]="sidePanel.file?.datamart?.contributors"
    />

    <!-- DOCUMENT DESCRIPTION  -->
    <side-panel-description
      [description]="sidePanel.file?.datamart?.description"
      [loading]="sidePanel.loadingSections.includes(Sections.DESCRIPTION)"
    />

    <!-- TAGS -->
    <side-panel-tags
      [language]="sidePanel.file?.datamart?.language"
      [loading]="sidePanel.loadingSections.includes(Sections.PROPERTIES)"
      [confidentiality]="documentConfidentiality"
      [documentType]="sidePanel.file?.datamart?.document_type"
      [tags]="sidePanel.file?.tags"
      [hasRights]="hasRights"
      [locked]="sidePanel.file?.is_locked"
      (editPropertiesCallback)="toggleDialog($event)"
    />

    <!-- DOCUMENT RATING  -->
    <side-panel-ratings
      [display]="!isDocumentRatedByMe"
      [loading]="sidePanel.loadingSections.includes(Sections.RATINGS)"
      (addRatingEvent)="toggleDialog($event)"
    />

    <!-- WORKFLOW ACTIVITY -->
    <side-panel-activity
      [workflows]="sidePanel.workflows"
      [labels]="sidePanel.file?.labels"
      [hasRights]="hasRights"
      [loading]="sidePanel.loadingSections.includes(Sections.WORKFLOW)"
      [locked]="sidePanel.file?.is_locked"
      (startWorkflowEvent)="startWorkflow()"
      (approveWorkflowEvent)="toggleDialog(DialogState.WORKFLOW)"
      (rejectWorkflowEvent)="rejectWorkflow()"
      (exportPdfEvent)="exportPdf($event)"
    />

    <!-- VERSIONNING -->
    <side-panel-versioning
      [fileVerifiedHistory]="sidePanel.verifiedHistory"
      [file]="sidePanel.file"
      [loading]="sidePanel.loadingSections.includes(Sections.VERSIONING)"
      [hasRights]="hasRights"
      (createNewVersion)="toggleDialog(DialogState.VERSIONING)"
    />
  </div>

  <!-- SIDEPANEL FOOTER-->
  <side-panel-footer
    [splitItems]="splitButtonItems"
    [likeButtonData]="likeButtonData"
    [ratings]="sidePanel.file?.ratings"
    [user]="sidePanel.connectedUser"
    [loading]="
      sidePanel.loadingSections.includes(Sections.LIKE) ||
      sidePanel.loadingSections.includes(Sections.RATINGS)
    "
    (addRatingEvent)="toggleDialog($event)"
  />
}

<!-- DIALOGS -->
<side-panel-dialogs
  [dialog]="dialogs[dialogToDisplay].component"
  [injector]="dialogs[dialogToDisplay].injector"
  [visible]="dialogToDisplay !== DialogState.NONE"
  [header]="dialogsHeader[dialogToDisplay]"
  [isLoading]="sidePanel.status === sidePanelStatus.LOADING"
  (hideDialogCallback)="toggleDialog($event)"
/>

<!-- LOCKED FILE -->
@if (
  sidePanel.file?.is_locked &&
  sidePanel.status !== sidePanelStatus.LOADING &&
  lockedTosterVisible
) {
  @if (hasRights) {
    <div class="bottom-action" (mousedown)="unlockFile()">
      <span>This document is locked</span>
      <span class="white-link">Unlock to edit</span>
    </div>
  } @else {
    <div class="bottom-action bottom-central">
      <span>This document is locked</span>
    </div>
  }
}
