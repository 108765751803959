import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from 'app/pages/side-panel/side-panel.types';
import { FunctionEmitter } from 'app/types';

@Component({
  selector: 'side-panel-status-screens',
  templateUrl: './side-panel-status-screens.component.html',
  styleUrl: './side-panel-status-screens.component.scss',
})
export class SidePanelStatusScreensComponent {
  @Input() sidePanelStatus: string = '';
  @Output() onWatch: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Output() onRefresh: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Output() onSensitivity: EventEmitter<FunctionEmitter> = new EventEmitter();

  readonly SidePanelStatus = Status;
}
