import { Component, Input } from '@angular/core';
import { Rating } from 'app/types';

@Component({
  selector: 'side-panel-title',
  templateUrl: './side-panel-title.component.html',
  styleUrl: './side-panel-title.component.scss',
})
export class SidePanelTitleComponent {
  @Input() title?: string = '';
  @Input() locked?: boolean = false;
  @Input() ratings?: Rating[] = [];
  @Input() loading: boolean = false;

  rating: number = 0;

  ngOnChanges() {
    if (this.ratings) {
      this.rating = 0;
      this.ratings.forEach((rating) => {
        this.rating += rating.rating;
      });
      this.rating = Math.round((this.rating / this.ratings.length) * 100) / 100;
    }
  }
}
