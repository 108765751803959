import { Component, Inject } from '@angular/core';
import { AngularComponentInputs, CONFIDENTIALITIES } from 'app/types';

@Component({
  selector: 'app-sensitivity',
  templateUrl: './sensitivity.component.html',
  styleUrl: './sensitivity.component.scss',
})
export class SensitivityComponent {
  readonly confidentialityOptions = CONFIDENTIALITIES;
  applySensitivity: Function = () => {};
  selectedConfidentiality: string = '';

  constructor(@Inject('OUTPUTS') public outputs: AngularComponentInputs) {
    this.applySensitivity = this.outputs.applySensitivity;
  }
}
